<template>
  <div class="w-full relative flex justify-center items-start bg-black dotted ">
    <div class="w-full h-full sm:h-screen overflow-hidden max-w-screen-2xl 2xl:p-4 bg-black">

      <div class="w-full h-full overflow-hidden flex flex-col justify-between  items-start bg-black 2xl:border 2xl:border-white/20 2xl:rounded-xl">
        <div class="w-full sm:flex hidden justify-center items-center ">
          <nav class="sticky top-0 z-[9999] w-full pl-4">
          <LazyNavigation ></LazyNavigation>
          <div id="appNav"></div>
        </nav>
        </div>

        <LazyNavigationMobileHeader class="sm:hidden"></LazyNavigationMobileHeader>


      <div class="w-full h-full overflow-hidden flex justify-center items-start">

        

        <div class="md:block hidden w-full overflow-hidden justify-center items-center rounded-xl" :class="[preferenceStore.showCalendar?'max-w-sm pl-4 h-full pb-4 ':'max-w-0']" >
          <div v-if="preferenceStore.showCalendar" class="border h-full w-full flex justify-center items-center border-white/10 bg-zinc-950 rounded-xl overflow-hidden group ">
          
            <ClientOnly>
              <LazyHomeCalendarPane class=" opacity-20 group-hover:opacity-100 duration-300" ></LazyHomeCalendarPane>
            </ClientOnly>
          
        </div>
        </div>
        
        <div class="w-full h-full overflow-auto relative">
          <slot />
        </div>

      </div>

      <LazyNavigationMobileFooter class="sm:hidden fixed bottom-4 left-0 z-50"></LazyNavigationMobileFooter>

      <!--<div class="px-4 mb-4 w-full flex justify-center items-center">

        <button class="btn bg-white/5 hover:bg-turquoise-500/10 hover:text-turquoise-500 duration-500 text-white border-none text-lg font-normal px-8">All Projects</button>

      </div>-->
      </div>
    </div>
  </div>
</template>

<script setup>
// const route = useRoute();

import { usePreferenceStore } from '~/stores/preference'

const preferenceStore = usePreferenceStore()

// const showCalendar = computed( () >)



</script>

<style>

.dotted {
    background-image: radial-gradient(#ffffff30 1px, transparent 0);
    background-size: 40px 40px;
    background-position: -19px -19px;
  }
  
  </style>
